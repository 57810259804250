<template>
    <div v-if="shop" class="bags-editor p-3">
        <!-- CloseButton -->
        <n-close-button path="home" />

        <div class="top-wrapper">
            <div class="header-container">
                <h4 class="font-27 font-weight-700 text-black mb-3 mt-2">你的購物車</h4>
            </div>
            <!-- Reservation -->
            <div class="reservation-container m-0">
                <!-- CartMethod -->
                <div class="text-center">
                    <div class="ship-methods mb-10">
                        <button v-if="shop.orderMethods.includes('PICKUP')" :class="{ 'bg-white text-black': cart.method === 'PICKUP' }" @click="cart.products.length > 0 ? null : (cart.method = 'PICKUP')">自取</button>
                        <button v-if="shop.orderMethods.includes('DELIVERY')" :class="{ 'bg-white text-black': cart.method === 'DELIVERY' }" @click="cart.products.length > 0 ? null : (cart.method = 'DELIVERY')">外送</button>
                    </div>
                </div>
                <!-- CartReservation -->
                <button class="content" @click.prevent="$router.push(`/${shop.id}/time`)">
                    <span v-if="!cart.reservationAt && cart.type != 'ASAP'">請選擇取餐時間</span>
                    <!-- MobileToGo -->
                    <span class="text-left" v-else-if="cart.method == 'PICKUP'">
                        <div class="font-17 font-weight-400 text-dark-warm-grey mb-1">{{ cart.type == 'ASAP' ? '馬上取餐' : '指定時間取餐' }}</div>
                        <div class="font-17 font-weight-500 text-black">{{ cart.type == 'ASAP' ? '越快越好（預估等待 ' + (shop.isBusy ? shop.busyTime : shop.readyTime) + '分鐘）' : cartReservationAt }}</div>
                    </span>
                    <!-- MobileDelivery -->
                    <span class="text-left" v-else-if="cart.method == 'DELIVERY'">
                        <span class="text-deep-blue font-17 font-weight-500" v-if="!cart.customer.address">設定外送地點</span>
                        <div v-else>
                            <div class="font-17 font-weight-400 text-dark-warm-grey mb-1">{{ cart.customer.address }}</div>
                            <div class="font-17 font-weight-500 text-black">{{ cart.type == 'ASAP' ? '越快越好（預估等待 ' + (shop.isBusy ? shop.busyTime : shop.readyTime) + '分鐘）' : cartReservationAt }}</div>
                        </div>
                    </span>
                    <img src="@/assets/icons/ic-morder-arrow-right.svg" width="24" height="24" />
                </button>
            </div>
            <!-- CartContainer -->
            <div v-if="cart.products.length > 0" class="cart-container">
                <!-- ShopName -->
                <div class="cart-header">
                    <div class="title">{{ shop.merchantName }} {{ shop.name }}</div>
                </div>
                <!-- ProductList -->
                <div v-for="(product, index) in cart.products" :key="index" class="cart-list">
                    <div class="cart-item">
                        <!-- ProductCount -->
                        <div class="select">
                            <select v-model="product.count" class="select-item" @change="onChangeCount">
                                <option v-for="option in 99" :key="option" :value="option">{{ option }}</option>
                            </select>
                        </div>
                        <!-- ProductInfo -->
                        <div class="main">
                            <div class="sub">
                                <div class="product-name">{{ product.name }}</div>
                                <div class="price">${{ product.total }}</div>
                            </div>
                            <div v-for="item in product.items" :key="item.id" class="sub">
                                <div class="item">{{ item.name }} ${{ item.price[cart.method] }}</div>
                            </div>
                        </div>
                        <!-- DeleteButton -->
                        <div class="delete">
                            <button @click="removeProduct(index)">移除</button>
                        </div>
                    </div>
                </div>
                <!-- DiscountList -->
                <div v-if="cart.discounts.length > 0" class="cart-list">
                    <div class="cart-item">
                        <span class="bg-white font-17 text-blue font-weight-700 text-center border-radius-16 mr-12 py-2 line-height-1" style="min-width: 48px">1</span>
                        <!-- ProductInfo -->
                        <div class="main">
                            <div class="sub">
                                <div class="product-name">{{ cart.discounts[0].name }}</div>
                            </div>
                        </div>
                        <!-- DeleteButton -->
                        <div class="delete">
                            <button @click="removeDiscount">移除</button>
                        </div>
                    </div>
                </div>
                <!-- TotalContainer -->
                <div class="total-container">
                    <div class="sub pl-84 pb-3">
                        <div>商品小計</div>
                        <div class="black">${{ cart.productTotal }}</div>
                    </div>
                    <div v-if="cart.discounts.length > 0" class="sub pl-84 pb-3">
                        <div>優惠小計</div>
                        <div class="black">${{ cart.discountTotal }}</div>
                    </div>
                    <div class="sub pl-84 pb-3">
                        <div>預計積分</div>
                        <div class="black">{{ cart.grandTotal }}分</div>
                    </div>
                    <div class="sub b-t-1">
                        <div class="emphasize">總計</div>
                        <div class="black-bold">${{ cart.grandTotal }}</div>
                    </div>
                </div>
            </div>
            <div v-else class="empty-container">
                <div class="name mb-3">{{ shop.merchantName }} {{ shop.name }}</div>
                <div class="description">您的購物車是空的，去挑些東西吧？</div>
            </div>
            <div class="border-container">
                <div class="input-container">
                    <div class="name">訂單優惠</div>
                    <div class="form mt-3 mb-2">
                        <div class="form-group">
                            <label class="bg-transparent">優惠碼</label>
                            <input v-model="discountCode" class="bags border-radius-8" style="padding-left: 4.75rem;" placeholder="請填寫優惠碼" />
                        </div>
                    </div>
                    <button v-if="currentDiscount" class="btn primary-btn flex-center" @click="addDiscount">
                        <span>加入優惠</span>
                    </button>
                </div>
            </div>
            <!-- RemarkContainer -->
            <div class="border-container note">
                <div class="note-container">
                    <div class="name">訂單備註</div>
                    <div class="description note">
                        <textarea v-model="cart.remark" placeholder="請寫下您的餐點特殊需求" cols="30" rows="2"></textarea>
                    </div>
                </div>
            </div>
            <!-- CustomerContainer -->
            <div v-if="customer" class="border-container">
                <div class="input-container">
                    <div class="name">會員資訊</div>
                    <div class="description">請填寫您的訂購資訊，方便於店家完成餐點時確認取餐者。</div>
                    <div class="form mt-3 mb-2">
                        <div class="form-group">
                            <label class="bg-transparent">姓名</label>
                            <input v-model="customer.name" class="bags border-radius-8" placeholder="請填寫姓名" />
                        </div>
                    </div>
                    <div class="form mt-3 mb-2">
                        <div class="form-group">
                            <label class="bg-transparent">電話</label>
                            <input v-model="customer.phone" class="bags border-radius-8" type="tel" placeholder="請填寫電話" />
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="border-container">
                <div class="note-container">
                    <div class="name">會員登入</div>
                    <div class="description"></div>
                    <button class="btn primary-btn flex-center" @click="$router.push(`/${shop.id}/login`)">
                        <span>前往登入</span>
                    </button>
                </div>
            </div>
            <!-- SubmitButton -->
            <div class="footer-container mb-3">
                <button @click="onSubmit" class="btn primary-btn flex-center" :class="{ disabled: !validateSubmit }" :disabled="!validateSubmit">
                    <span v-if="shop.isClosed">此商店暫時關閉接單</span>
                    <span v-else>送出訂單</span>
                </button>
            </div>
            <!-- 訂單資料確認 -->
            <div v-if="confirmShow" class="popup-container">
                <div class="popup-detail">
                    <div v-if="cart.method == 'PICKUP'" class="title">你的訂單-自取</div>
                    <div v-if="cart.method == 'DELIVERY'" class="title">你的訂單-外送</div>
                    <div class="content">
                        <div v-if="cart.method == 'DELIVERY'">
                            <label>地址</label>
                            <p>{{ cart.customer.address ? cart.customer.address : '未填寫地址' }}</p>
                        </div>
                        <div>
                            <label>時間</label>
                            <p>{{ cart.type == 'ASAP' ? '越快越好（預估等待 ' + (shop.isBusy ? shop.busyTime : shop.readyTime) + '分鐘）' : cartReservationAt }}</p>
                        </div>

                        <label>訂單總計</label>
                        <p>${{ cart.grandTotal }}</p>
                    </div>
                    <div class="action">
                        <button class="bg-grey text-white border-radius-8" @click="cancelSubmit">取消</button>
                        <button class="bg-blue text-white border-radius-8" @click="confirmSubmit">確定</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'

export default {
    data() {
        return {
            currentDiscount: null,
            discountCode: '',
            confirmShow: false,
        }
    },

    async created() {
        if (localStorage.getItem(`${this.shop.merchantId}-customerId`)) await this.$store.dispatch('bindDocument', { col: 'customers', id: localStorage.getItem(`${this.shop.merchantId}-customerId`), target: 'customer' })

        await this.$store.dispatch('bindCollectionByMerchant', { col: 'discounts', merchantId: this.shop.merchantId })

        if (this.cart.discounts.length > 0) this.discountCode = this.cart.discounts[0].code
    },

    watch: {
        discountCode: function(val) {
            console.log(this.discounts)
            this.currentDiscount = this.discounts.find(discount => discount.code == val)
        },
    },

    computed: {
        ...mapState({
            shop: state => state.shop,
            cart: state => state.cart,
            customer: state => state.customer,
            discounts: state => state.discounts,
        }),

        // 計算送餐時間句子
        cartReservationAt() {
            return moment.unix(this.cart.reservationAt).format('MM/DD HH:mm')
        },

        validateSubmit() {
            if (this.shop.isClosed) return false
            if (!this.cart.reservationAt && this.cart.type != 'ASAP') return false
            if (this.cart.products.length < 1) return false
            if (this.cart.grandTotal < 0) return false
            if (!this.customer) return false
            if (this.cart.method === 'DELIVERY'  && this.cart.customer.address === '') return false
            return true
        },
    },

    methods: {
        onChangeCount() {
            console.log('onChangeCount')
            this.$store.dispatch('updateCartTotals')
        },

        removeProduct(index) {
            return this.$store.dispatch('removeCartProduct', { index })
        },

        addDiscount() {
            return this.$store.dispatch('addCartDiscount', { discount: this.currentDiscount })
        },

        removeDiscount() {
            return this.$store.dispatch('removeCartDiscount', 0)
        },

        onSubmit() {
            this.confirmShow = !this.confirmShow
        },
        confirmSubmit() {
            this.$router.push(`/${this.shop.id}/payment`)
        },
        cancelSubmit() {
            this.confirmShow = !this.confirmShow
        },
    },
}
</script>

<style scoped>
.popup-container {
    position: fixed;
    z-index: 99999;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
}

.popup-container .popup-detail {
    background: white;
    width: 80%;
    min-height: 10%;
    max-height: 70%;
    overflow: scroll;
    border-radius: 16px;
    display: inline-flex;
    flex-direction: column;
    justify-items: center;
    padding: 2rem;
    margin: auto auto;
}

.popup-detail .content {
    padding: 2rem 0;
    font-size: 1.1rem;
}
.popup-detail .title {
    font-size: 1.8rem;
    font-weight: 800;
}

.popup-detail label {
    font-weight: 800;
}

.popup-detail .action {
    display: flex;
    justify-content: space-around;
}

.action Button {
    padding: 0.5rem 1rem;
    width: 40%;
}
</style>
